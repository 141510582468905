import { CommonModule } from '@angular/common';
import { Component, Inject, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '@app/components/button/button.component';
import { DialogBase } from '@app/components/dialog/dialog-base';
import { InputComponent } from '@app/components/input/input.component';
import { NoteColors } from '../../enums/note-colors';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ta } from 'date-fns/locale';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'app-game-dialog-player-info',
  standalone: true,
  imports: [CommonModule, ButtonComponent, InputComponent, ReactiveFormsModule, AngularSvgIconModule],
  templateUrl: './game-dialog-player-info.component.html',
  styleUrl: './game-dialog-player-info.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: { class: 'dialog app-game-dialog-player-info' }
})
export class GameDialogPlayerInfoComponent extends DialogBase {
  private readonly _fb = inject(FormBuilder);

  isEditMode = true;
  enumNoteColors = NoteColors;
  noteColors = Object.keys(NoteColors).slice(Object.keys(NoteColors).length / 2)
  form = this._fb.nonNullable.group<{ noteText: string, noteColor: string }>({
    noteText: '',
    noteColor: '',
  });

  constructor(@Inject(DIALOG_DATA) public data: {
    playerId: number,
    playerName: string,
    tableId: number,
    noteText: string,
    noteColor?: keyof typeof NoteColors
  }) {
    super()
    if (data.noteColor) {
      if (NoteColors[data.noteColor] === undefined) { // if color is not valid set default color
        data.noteColor = '#EBEBEB';
      }
      this.form.get('noteColor')?.setValue(data.noteColor)
    }
    if(data.noteText) {
      this.form.get('noteText')?.setValue(data.noteText)
      this.isEditMode = false
    }
  }
  setNoteColor(noteColor: string): void {
    this.form.get('noteColor')?.setValue(noteColor)
  }
  clear() {
    this.form.reset()
    this.save()
  }

  save() {
    this.dialogRef.close({
      ...this.form.value,
      tableId: this.data.tableId,
      playerId: this.data.playerId
    })
  }

  viewEditMode() {
    this.isEditMode = true
  }
}
