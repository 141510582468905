import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import * as GamesActions from './games.actions';


import * as AuthActions from '@app/store/features/auth/auth.actions';
import { TableInfo } from '@app/models/table-info';
import { MixTableDetails } from '@app/models/mix-table-details';
import { TableSummary } from '@app/models/table-summary';
import { Assets, Application, Container, Sprite } from 'pixi.js';
import { CurrencyInfo } from '@app/models/currency-info';
import { CardData } from '@app/features/game/models/card-data';
import { PlayerStatus } from '@app/features/game/enums/player-status.enum';
import { Limit } from '@app/models/limit';
import { HandHistory } from '@app/models/hand-history';
import { PlayerCallTime } from '@app/models/player-call-time';
import { TableSession, TableSessionType } from '@app/features/game/models/table-session';
import { HandInfo } from '@app/models/hand-info';
import { VariantType2 } from '@app/enums/variant-type-2';

export type MaxPlayers = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export enum MemberPreferencesRunItTwice {
    ON = 1,
    OFF = 0,
    ASK = 2
}

export enum ReplayAction {
    Play = 1,
    Next,
    Pause,
    Restart,
    None
}

export interface GamePlayer {
    id: number;
    name: string;
    avatar: string;
    money: number;
    rank: number;
    status: PlayerStatus;
    moneyBidTotal: number;
    country: string;
    city: string;
    timeBank: number;
    runItTwice: number;
    playerCallTime: PlayerCallTime;
    playerSpells: {
        bountySpell: {
            useSpellAgainst: any[];
            spellVariant: number;
            showSpell: boolean;
            status: number;
        },
        earnedSpells: any[];
    }
    cardsReplaced: any[]
    mark: number; // 1 - ProPlayer; // To differentiate some player mark (pro-player for instance)

    // add to do:
    nbRebuy: number;

    currentCheckValue?: number;
    currentPotValue?: number;
    currentAllInValue?: number;
    cards: CardData[];
    isDealer: boolean;
    alreadyBetInThisRound: number;
    isPlayerTurn: boolean;
    potValue: number;
}
export enum GameStatus {
    WaitingPlayers = 1, // then place dealer, take blinds, the distribute 2 cards to each player
    PreFlop = 2, // then distribute the 3 flop card
    Flop = 3, // then distribute the 1 card
    Turn = 4, // then distribute the 1 last card
    River = 5, // last bidding round, then the game end
    Showdown = 6, // winners
    SeventhStreet = 8, // extra bidding for 7 card stud
    TripleDrawBettingRound1 = 9,
    ReplaceCards1 = 11,
    TripleDrawBettingRound2 = 12,
    ReplaceCards2 = 13,
    TripleDrawBettingRound3 = 14,
    ReplaceCards3 = 15,
    TripleDrawBettingRound4 = 16,
}

export interface GameHistoryEvent {
    source: string;
    data: string
    cards?: CardData[];
}

export enum GameEvent {
    TableInit = 'Table opened',
    HandStart = 'Hand starts',
    PlayerFold = 'Folded',
    PlayerBidAnte = 'Ante',
    PlayerBidSmallBlind = 'Posts Small Blind',
    PlayerBidBigBlind = 'Posts Big Blind',
    PlayerBidCheck = 'Checked',
    PlayerBidBet = 'Bet',
    PlayerBidCall = 'Called',
    PlayerBidRaise = 'Raised',
    PlayerBidAllIn = 'All In',
    PlayerTurnChange = 'On The Move',
    PlayerRevealCard = 'Reveal Cards',
    MoveToPot = 'Bets Moved To Pot',
    WinnerByFold = 'Won Hand By Fold',
    WinnerByStrongestHand = 'Won with ',
    WinnerSplit = 'Won Split',
    PlayerLeaveTable = 'Leaves Table',
    PlayerTakeSeat = 'Join Table',
    GamePause = 'Game Paused',
    VariantChanged = 'Variant Changed',
    LimitChanged = 'Limit Changed',
    EndOfHand = 'End Of Hand',
    PlayerDealer = 'Is The Dealer',
    CommunityCards = 'Community Cards ',
    DealFlop = 'Dealt Flop',
    DealTurn = 'Dealt Turn',
    DealRiver = 'Dealt River',
    DiscardedCards = 'Discarded',
    StandPat = 'Stand Pat',
    RequestedRabbitHunting = 'Requested rabbit hunting',
    PostedStraddle = 'Posted Straddle',
}

export interface GameActionControls {
    checkSeatOutNextHand: boolean;
    showImBackButton: boolean;
    showBuyRebuyChipsButton: boolean;
    showTipButton: boolean;
    showLeaveTableButton: boolean;
    showReplayButtons: boolean;
    checkRunItTwice: MemberPreferencesRunItTwice,
    isStraddle: boolean
    disableChat: boolean;
};

export enum PreBetType { checkFold = 0, check = 1, call = 2, callAny = 3 }

export interface PreBet {
    type: PreBetType;
    text: string;
    visible: boolean;
    checked: boolean;
    value?: string;
    cssClass: string;
}

export interface GamePreBetControls {
    show: boolean;
    values: Record<PreBetType, PreBet>;
    selected: undefined | PreBetType,
}

export interface GamePreBetControlsView extends GamePreBetControls {

    show: boolean;
    values: Record<PreBetType, PreBet>;
    selected: undefined | PreBetType,
}

export interface BetSlider {
    min: number;
    max: number;
    step: number;
    value: number;

    playerBalance: number;// new

    bigBlind: {
        min: number;
        max: number;
        step: number;
        value: number;

        playerBalance: number;
    }
}

export interface GameBetControls {
    showOfferRabbitHunting: boolean;

    showReplaceCardsButtons: boolean;

    showPostBigBlind: boolean;
    showBetButtons: boolean;
    showFoldButton: boolean;
    showCallButton: boolean;
    showCheckButton: boolean;
    showRaiseButton: boolean;

    justForShowCallValue: number;
    justForShowCallValueBB: string;

    raiseButtonText: string;
    raiseButtonValue: string;
    raiseButtonValueBigBlind: string;

    betSlider: BetSlider;
    showSlider: boolean;
    showBB2Button: boolean;
    showBB3Button: boolean;
    showBB4Button: boolean;
    showPotButton: boolean;
    showHalfPotButton: boolean;
    showQuarterPotButton: boolean;
    showThreeQuartersPot: boolean;
    showMaxButton: boolean;
    callValue: number;
    potValue: number;
    minimumBet: number;
    isBetPreload: boolean;
    callButtonText: string;
    isBringIn: number;
    checkButtonText: string;
    straddle: boolean;
    amount7StudBet: number;
}


export interface GameChat {
    text: string;
    color: string
    player: {
        id: number;
        name: string;
        avatar: string;
        rank: number;
    }
    date: Date;
    localTimestamp: number;
}

export interface GamePlayerPlayStatistic {
    idPlayer: number;
    nbHandsPlayed: number;
    nbHandsWon: number;
    nbHandsLost: number;
    totalBet: number;
    totalWon: number;
    profit: number;

    moneyTotalBet: string;
    moneyTotalWon: string;
    moneyProfit: string;
}

export interface Game {
    idTable: number;
    currency: CurrencyInfo;
    currencyId: number;

    maxNumOfPlayers: MaxPlayers;
    seats: (GamePlayer | null)[];
    variant: VariantType2;
    isFast: boolean;
    limit: Limit;
    blind: number;
    
    minStake: number;
    maxStake: number;

    ante: number;
    bringIn: number;
    rotateEvery: number;
    rotateIndex: number;
    numberOfTableInRotation: number;

    gameStatus?: GameStatus;
    action?: any;

    gameHistoryEvents: GameHistoryEvent[];

    gameActionControls: GameActionControls;
    gamePreBetControls: GamePreBetControls;
    gameBetControls: GameBetControls;
    gamePrePlayHandInfo: {
        previousHighestBet: number,
    };

    currentHandNumber: number;
    previousHandNumber?: number;




    handType: string;
    tableCards: CardData[];

    potTotalValue: number;
    mainPotValue: number;
    mainPot2Value: number;

    hasSexyDealer: boolean;
    isTournament: boolean;
    pauseOverlay: {
        show: boolean;
        timer: number;
    }
    playerTurnId: number | null;

    tournamentId?: number;

    tableName: string;
    cardsInHand: CardData[]; // ! for nav tab
    onMove: boolean;  // ! for nav tab
    folded: boolean;  // ! for nav tab


    replayEvents?: any[];
    gameReplayEvents?: any[];
    gameReplayEventsIndex?: number;
    gameReplayAction?: ReplayAction;
    handNumber?: number;
    isReplay: boolean;

    /**
        * ADD
        * potsOnTableTotalValue 
    */

    chat: GameChat[]
    playersStatistics: Record<number, GamePlayerPlayStatistic>; // KEY PlayerID

    // RabbitHunting
    freeRabbitHunting?: number;
    chargedRabbitHunting: ChargedRabbitHunting[];
    rabbitHuntingPrice?: RabbitHuntingPrice;
    startedRabbitHuntingPlayerId?: number;


}

export enum ChargedRabbitHuntingType {
    Free = 0,
    PercentageOfBigBlind = 1,
    Emotikens = 2,
    Other = 3
}

export interface ChargedRabbitHunting {
    type: ChargedRabbitHuntingType;
    amount: number;
    currency: CurrencyInfo;
    currencyId: number;
}

export interface RabbitHuntingPrice {
    type: ChargedRabbitHuntingType;
    amount: number;
    currency: CurrencyInfo;
    currencyId: number;
}

export interface State extends EntityState<Game> {
    activeTableId: number | null;
    handHistory: HandHistory | null;
    tableSessions: {
        totalRows: number;
        sessions: TableSession[];
        search: {
            from?: string;
            to?: string;
            offset?: number;
            limit?: number;
            type?: TableSessionType;
        },
        loader: boolean;
    },
    handSessions: {
        totalRows: number;
        sessions: HandInfo[];
        search: {
            handsSessionId: number;
            offset?: number;
            limit?: number;
        },
        loader: boolean;
    }


    freeRabbitHunting: number | null;
    openedTables: number[];
}


export const adapter: EntityAdapter<Game> = createEntityAdapter<Game>({
    selectId: (model: Game) => model.idTable,
    sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
    activeTableId: null,
    handHistory: null,
    tableSessions: {
        totalRows: 0,
        sessions: [],
        search: {
            from: undefined,
            to: undefined,
            offset: undefined,
            limit: undefined,
            sessionType: undefined
        },
        loader: false
    },
    handSessions: {
        totalRows: 0,
        sessions: [],
        search: {
            handsSessionId: 0,
            offset: undefined,
            limit: undefined
        },
        loader: false
    },
    freeRabbitHunting: null,
    openedTables: []
});


export const reducer = createReducer(initialState,
    on(GamesActions.upsertOne, (state, { game }) => {
        return adapter.upsertOne({ ...game, idTable: game.idTable, }, { ...state });
    }),

    on(GamesActions.removeOne, (state, { idTable }) => {
        return adapter.removeOne(idTable, { ...state, openedTables: state.openedTables.filter(id => id !== idTable) });
    }),

    on(GamesActions.updateOne, (state, { idTable, game }) => {
        console.log('rabb updateOne', idTable, game);
        return adapter.updateOne({ id: idTable, changes: { ...game } }, { ...state });
    }),

    on(AuthActions.onLogout, (state) => {
        return adapter.removeAll({ ...state });
    }),

    on(GamesActions.setActiveTable, (state, { idTable }) => {
        return { ...state, activeTableId: idTable, openedTables: state.openedTables.concat(idTable) };
    }),

    on(GamesActions.unsetActiveTable, (state) => {
        return { ...state, activeTableId: null };
    }),

    on(GamesActions.freeRabbitHunting, (state, rabbitHunting) => {
        if (rabbitHunting.idTable) {
            return adapter.updateOne({ id: rabbitHunting.idTable, changes: { freeRabbitHunting: rabbitHunting.value ?? null } }, { ...state, freeRabbitHunting: rabbitHunting.value ?? null });
        }


        return { ...state, freeRabbitHunting: rabbitHunting.value ?? null };
    }),

    on(GamesActions.chargedRabbitHunting, (state, { idTable, chargedRabbitHunting }) => {
        return adapter.updateOne({ id: idTable, changes: { chargedRabbitHunting: state.entities[idTable]?.chargedRabbitHunting.concat(chargedRabbitHunting) } }, { ...state });
    }),

    on(GamesActions.updateRabbitHuntingPrice, (state, { idTable, rabbitHuntingPrice }) => {
        return adapter.updateOne({ id: idTable, changes: { rabbitHuntingPrice } }, { ...state });
    }),

    on(GamesActions.updateHandHistory, (state, handHistory) => {
        return { ...state, handHistory };
    }),

    on(GamesActions.updateTableSessions, (state, { tableSessions }) => {
        return { ...state, tableSessions };
    }),

    on(GamesActions.updateHandSessions, (state, { handSessions }) => {
        return { ...state, handSessions };
    }),
);
