import { Injectable, inject } from '@angular/core';
import { LoginRequest } from '../models/login-request';
import { ServerRequestMessage } from '@app/enums/server-request';
import { SecurityCodeRequest } from '../models/security-code-request';
import { Observable, map } from 'rxjs';
import { ServerMessageType } from '@app/enums/server-message-type';
import { ServerMsgAccountRequestSecurityCodeResponse } from '@app/models/server-msg';
import { WsService } from '@app/services/ws.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly _ws = inject(WsService);
  private readonly _httpClient = inject(HttpClient);

  loginUsernamePassword(apiUrl: string, skin: string, username: string, password: string) {
    // NEW: REST API
    const clientName = 'HTML-v4-I5QAFP3 00FF5DEAA0FA';
    const device = 'desktop';
    const version = '4.0.0';
    const platform = 2; // platform (1 for Windows, 2: HTML, 3:Android, 4:iOS, 7:Mac, 8:Bot)
    const guid = `${Math.random()}`;
    return this._httpClient.post<{
      token: string;
      url: string; // WS URL;
      Code: number;
      Message: string;
    }>(`${apiUrl}/ApiGame/GameToken`, {
      username,
      password,
      clientName,
      skin,
      device,
      version,
      platform,
      guid
    })


    // OLD: WEBSOCKET
    // const data: LoginRequest = {
    //   type: ServerRequestMessage.Connect,
    //   Str1: username.trim(),
    //   Str2: password,
    // }
    // this._ws.sendData(data);
  }

  loginWithSecurityCode(email: string, securityCode: string) {
    const data: LoginRequest = {
      type: ServerRequestMessage.Connect,
      Str1: email.trim(),
      Str4: securityCode.trim(),
    }
    this._ws.sendData(data);
  }

  logout(): void {
    this._ws.sendData({ type: ServerRequestMessage.Disconnect });
  }

  // Forgot Password Action
  requestSecurityCode(email: string): void {
    const data: SecurityCodeRequest = {
      type: ServerRequestMessage.AccountRequestSecurityCode,
      Str1: email.trim()
    }
    this._ws.sendData(data);
  }


  getAccountRequestSecurityCodeResponse(): Observable<ServerMsgAccountRequestSecurityCodeResponse> {
    return this._ws.getServerMsg<ServerMsgAccountRequestSecurityCodeResponse>(ServerMessageType.AccountRequestSecurityCodeResponse)
  }

}
