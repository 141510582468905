import { TableSummary } from '@app/models/table-summary';
import { createAction, props } from '@ngrx/store';

export const updateLoader = createAction('[Table Summaries] Update Loader', props<{ loader: boolean }>());
export const setAll = createAction('[Table Summaries] Set All Table Summaries', props<{ tableSummaries: TableSummary[] }>());
export const updateOne = createAction('[Table Summaries] Update Table Summary', props<{ tableId: number; tableSummary: Partial<TableSummary> }>());
export const removeOne = createAction('[Table Summaries] Remove Table Summary', props<{ tableId: number }>());


export const getTableSummary = createAction('[Table Summaries] getTableSummary', props<{ tableId: number }>());
