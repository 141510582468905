<div class="dialog-header">
    <div class="dialog-header-title">
        {{data.playerName}}
    </div>
    <div class="dialog-header-close-button">
        <button (click)="close()">
            <svg-icon src="assets/img/icons/dialog-close.svg"
                [stretch]="true"
                [applyClass]="true"
                class="dialog-header-close-button-icon" />
        </button>
    </div>
</div>
<ng-container *ngIf="isEditMode; else viewMode">
    <div class="dialog-content">
        <form [formGroup]="form">
            <div class="app-game-dialog-player-info-edit-mode">
                <div class="app-game-dialog-player-info-edit-mode-note-text">
                    <app-input placeholder="Add Note"
                        formControlName="noteText"></app-input>
                </div>

                <div class="app-game-dialog-player-info-edit-mode-note-icon">
                    <div *ngFor="let noteColor of noteColors; let i=index"
                        class="app-game-dialog-player-info-edit-mode-note-icon-option"
                        [ngClass]="{'active': form.value.noteColor===noteColor }"
                        (click)="setNoteColor(noteColor)">
                        <img
                            src="assets/common/player/note-icons/{{enumNoteColors[$any(noteColor)]}}.png"
                            alt="*">
                    </div>
                </div>
            </div>
        </form>

    </div>
    <div class="dialog-footer">
        <div class="app-game-dialog-player-info-edit-mode-footer">
            <button class="flat-dark-light" appButton
                (click)="clear()">Clear</button>
            <button class="success" appButton (click)="save()">Save</button>
            <button appButton (click)="close()">Cancel</button>
        </div>
    </div>
</ng-container>

<ng-template #viewMode>
    <div class="dialog-content">
        <div class="app-game-dialog-player-info-view-mode-content">
            <div class="app-game-dialog-player-info-view-mode-content-note-icon" *ngIf="data.noteColor">
                <img
                    src="assets/common/player/note-icons/{{enumNoteColors[$any(data.noteColor)]}}.png"
                    alt="*">
            </div>
            <div class="app-game-dialog-player-info-view-mode-content-note-text">
                {{data.noteText}}
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <div class="app-game-dialog-player-info-view-mode-footer">

            <button class="app-game-dialog-player-info-view-mode-footer-button success" appButton
                (click)="viewEditMode()">Edit</button>
            <button class="app-game-dialog-player-info-view-mode-footer-button" appButton (click)="close()">Cancel</button>
        </div>
    </div>
</ng-template>