<div class="dialog-header">
    <div class="dialog-header-title" *ngIf="title">
        {{title}}
    </div>
    <div class="dialog-header-close-button">
        <button (click)="close()">
            <svg-icon src="assets/img/icons/dialog-close.svg"
                [stretch]="true"
                [applyClass]="true"
                class="dialog-header-close-button-icon" />
        </button>
    </div>
</div>
<div class="dialog-content">
    <div class="app-generic-dialog-image" *ngIf="image">
        <img [src]="image" alt="*">
    </div>
    <div class="app-generic-dialog-text" *ngIf="text"
        [innerHTML]="text| safeHtml"></div>
</div>
<div class="dialog-footer">
    <div class="app-generic-dialog-footer">

        <button *ngIf="confirmBtn" appButton
            (click)="confirm()">{{confirmBtn}}</button>

        <a *ngIf="linkBtn" appButton (click)="close()" target="_blank"
            href="{{linkBtn.href}}" class="success">{{linkBtn.text}}</a>

        <button *ngIf="dissmissBtn" appButton
            (click)="close()">{{dissmissBtn}}</button>

    </div>

</div>