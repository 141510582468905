import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ButtonComponent } from '@app/components/button/button.component';
import { DialogBase } from '@app/components/dialog/dialog-base';
import { Subscription, interval } from 'rxjs';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'app-game-dialog-ask-show-cards',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    AngularSvgIconModule
  ],
  templateUrl: './game-dialog-ask-show-cards.component.html',
  styleUrl: './game-dialog-ask-show-cards.component.scss',
  host: { class: 'dialog app-game-dialog-ask-show-cards' },
  encapsulation: ViewEncapsulation.None
})
export class GameDialogAskShowCardsComponent extends DialogBase implements OnDestroy {

  countDown_: Subscription
  timer: number

  constructor(@Inject(DIALOG_DATA) public data: { time: number }) {
    super()
    this.timer = data.time

    this.countDown_ = interval(1000).subscribe(() => {
      this.timer--

      if (this.timer <= 0) { this.cancel() }
    })
  }

  ngOnDestroy() {
    this.countDown_.unsubscribe()
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.countDown_.unsubscribe()
    this.dialogRef.close();
  }

}
