import { CommonModule } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { ButtonComponent } from '@app/components/button/button.component';
import { DialogBase } from '@app/components/dialog/dialog-base';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { PlayerCallTime } from '@app/models/player-call-time';
import { Observable, tap, of, switchMap, timer } from 'rxjs';
import { AngularSvgIconModule } from 'angular-svg-icon';

interface CountdownTimer {
  days: string
  hours: string
  minutes: string
  seconds: string
  finished: boolean
}

@Component({
  selector: 'app-game-call-time-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    AngularSvgIconModule
  ],
  templateUrl: './game-call-time-dialog.component.html',
  styleUrl: './game-call-time-dialog.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: { class: 'dialog app-game-call-time-dialog' }
})
export class GameCallTimeDialogComponent extends DialogBase {

  callTimeCountdownTimer$: Observable<CountdownTimer | null>
  constructor(@Inject(DIALOG_DATA) public data?: PlayerCallTime) {
    super()

    this.callTimeCountdownTimer$ = of(data).pipe(
      switchMap((playerCallTime) => {
        console.log('🎲 playerCallTime', playerCallTime)
        if (!playerCallTime || playerCallTime!.isActivated === false) {
          return of(null)
        }
        const expirationDate = new Date(new Date(playerCallTime!.activatedAt).getTime() + playerCallTime!.callTimeDuration * 1000)
        console.log('🎲 expirationDate', expirationDate)

        return timer(0, 500)
          .pipe(
            switchMap(() => of(this.countDown(expirationDate))),
            tap((timer) => {
              console.log('🎲 timer', timer)
            })
          )

      })
    )
  }

  countDown(expiresAt: Date): CountdownTimer {

    let t = Math.floor((expiresAt.getTime() - new Date().getTime()) / 1000);
    let days, hours, minutes, seconds;
    days = Math.floor(t / 86400)
    t -= days * 86400;
    hours = (Math.floor(t / 3600) % 24)
    t -= hours * 3600;
    minutes = (Math.floor(t / 60) % 60)
    t -= minutes * 60
    seconds = (t % 60)
    let data: CountdownTimer = {
      days: `${days}`,
      hours: `${hours}`,
      minutes: `${minutes}`,
      seconds: `${seconds}`,
      finished: false
    }

    if ((days == 0 && hours == 0 && minutes == 0 && seconds == 0) || (days < 0 || hours < 0 || minutes < 0 || seconds < 0)) {
      data = {
        days: `00`,
        hours: `00`,
        minutes: `00`,
        seconds: `00`,
        finished: true
      }
    } else {
      if (days < 10) data.days = '0' + days
      if (hours < 10) data.hours = '0' + hours
      if (minutes < 10) data.minutes = '0' + minutes
      if (seconds < 10) data.seconds = '0' + seconds
    }

    return data
  }

  startCallTime(): void {
    this.dialogRef.close(true)
  }
}
