import { HostListener, Injectable, inject } from '@angular/core';
import { map, switchMap, tap, filter, delay } from 'rxjs/operators';
import { WsService } from 'src/app/services/ws.service';
import { ServerMessageType } from '@app/enums/server-message-type';
import { ServerMsgAccountRequestSecurityCodeResponse, ServerMsgConnectResponse, ServerMsgError } from '@app/models/server-msg';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';
import { AuthService } from '../../../features/auth/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as AuthSelectors from './auth.selectors';
import { ToastrService } from 'ngx-toastr';
import * as AuthActions from '@app/store/features/auth/auth.actions';
import * as  WSActions from '@app/store/features/web-socket/web-socket.actions';
import { combineLatest, take } from 'rxjs';
import { ConnectionStatus } from '@app/enums/connection-status';
import { GlobalSettingsAPIResponse } from '@app/models/global-settings';
import { ServerResponse } from '@app/enums/server-response';
import { ErrorCode } from '@app/enums/errors';
import * as ConfigSelectors from '@app/store/features/config/config.selectors';
import * as ConfigActions from '@app/store/features/config/config.actions';

@Injectable()
export class AuthEffects {
    private readonly _ws = inject(WsService);
    private readonly _actions$ = inject(Actions);
    private readonly _store = inject(Store);
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _router = inject(Router);

    constructor(
        private readonly _authService: AuthService,
        private readonly router: Router,
        private readonly _toastr: ToastrService
    ) {
    }



    // 🌐 WebSocket 
    wsLoginStatus$ = createEffect(() =>
        this._store.pipe(select(ConfigSelectors.getDefaultAssetsLoaded)).pipe(
            tap(loaded => console.log("🔌 Default Assets Loaded", loaded)),
            filter(loaded => loaded === 100),
            switchMap(() => this._ws.getServerMsg<ServerMsgConnectResponse>(ServerMessageType.ConnectResponse)),
            map(connectionResponse => {

                if (connectionResponse.errorCode) {

                    this._toastr.error(`${connectionResponse.text}`, `Error Code ${connectionResponse.errorCode}`);

                    // It means that local storage is supported
                    if (typeof Storage !== "undefined") {
                        localStorage.removeItem('authCredentials');
                    }

                    return AuthActions.loginError()
                }

                const returnUrl = this._activatedRoute.snapshot.queryParams["returnUrl"]
                console.log("#returnUrl", returnUrl)
                if (returnUrl) {
                    this._router.navigateByUrl(returnUrl)
                } else {
                    this._router.navigate(['/lobby'])
                }

                return AuthActions.loginSuccessful({ userId: connectionResponse.idPlayer! })
            })
        ))

    wsAccountRequestSecurityCodeResponse$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgAccountRequestSecurityCodeResponse>(ServerMessageType.AccountRequestSecurityCodeResponse)
            .pipe(
                map(requestSecurityCodeResponse => {
                    if (requestSecurityCodeResponse.errorCode) {
                        console.log("❌ Error on AccountRequestSecurityCodeResponse", requestSecurityCodeResponse)
                        this._toastr.error(`${requestSecurityCodeResponse.text}`, `Error Code ${requestSecurityCodeResponse.errorCode}`);

                        return AuthActions.updateForgotPasswordEmail({ email: '' })
                    }
                    return AuthActions.updateForgotPasswordEmail({ email: requestSecurityCodeResponse.text2! });
                })
            ));

    autoLogin$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ConfigActions.assetsLoaded),
                tap(() => {
                    if (typeof Storage !== "undefined") {
                        const authCredentials = localStorage.getItem('authCredentials')
                        console.log('🔐 authCredentials', authCredentials)
                        if (authCredentials) {
                            const { username, password } = JSON.parse(authCredentials)
                            if (username && password) {
                                this._store.dispatch(AuthActions.onLoginWithUsernamePasswordFromCredentials({ username, password }))
                            }
                        }
                    }
                }),
            ),
        { dispatch: false }
    );

    // 🕹️ App  
    onLogout$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AuthActions.onLogout),
                tap(() => {
                    this._authService.logout();
                    this._ws.disconnect();
                    this.router.navigate(['/auth/login']);

                    // It means that local storage is supported
                    if (typeof Storage !== "undefined") {
                        localStorage.removeItem('authCredentials');
                    }
                }),
            ),
        { dispatch: false }
    );


    onLoginWithUsernamePasswordSaveCredentials$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AuthActions.onLoginWithUsernamePassword),
                tap(({ username, password, rememberMe }) => {
                    // It means that local storage is supported
                    if (typeof Storage !== "undefined") {
                        if (rememberMe) {
                            localStorage.setItem('authCredentials', JSON.stringify({ username, password, date: new Date() }))
                        } else {
                            localStorage.removeItem('authCredentials');
                        }
                    }
                })
            ),
        { dispatch: false }
    );

    onLoginWithUsernamePassword$ = createEffect(
        () =>
            combineLatest([

                this._store.pipe(select(ConfigSelectors.getDomainSettings),
                    filter(domainSettings => !!domainSettings!),
                    map(domainSettings => domainSettings!),
                    take(1),
                ),
                this._actions$.pipe(
                    ofType(
                        ...[
                            AuthActions.onLoginWithUsernamePassword,
                            AuthActions.onLoginWithUsernamePasswordFromCredentials
                        ]
                    ))
            ]).pipe(
                tap(([domainSettings, { username, password }]) => console.log('👤', username, password)),
                switchMap(([domainSettings, { username, password }]) => this._authService.loginUsernamePassword(
                    domainSettings.httpUrl,
                    domainSettings.skinName,
                    username,
                    password
                )),
                map(({ token, url, Code, Message }) => {
                    if (Code !== 0) {
                        this._toastr.error(`${Message}`, `Error Code ${Code}`);
                        return AuthActions.loginError()
                    }
                    return ConfigActions.updateAuthSettings({ authSettings: { token, url } })
                }),
            ));

    onRequestSecurityCode$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AuthActions.onRequestSecurityCode),
                tap(({ email }) => this._authService.requestSecurityCode(email)),
            ),
        { dispatch: false }
    );

    onLoginWithSecurityCode$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AuthActions.onLoginWithSecurityCode),
                switchMap(({ securityCode }) => {
                    return this._store.pipe(select(AuthSelectors.forgotPassword))
                        .pipe(
                            tap(({ email }) => this._authService.loginWithSecurityCode(email, securityCode)),
                        )
                })
            ),
        { dispatch: false }
    );

    tournamentError$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgError>(ServerMessageType.Error)
            .pipe(
                filter(error => [ErrorCode.LoginConnectionFromAnotherDevice].indexOf(error.errorCode) > -1),
                tap(error => this._toastr.warning(`You have logged in from a different device.`, `Auth`)),
                map(error => AuthActions.onLogout())
            )
    );
}




