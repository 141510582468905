import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogBase } from '../dialog/dialog-base';
import { BroadCastMessageInfo } from '@app/models/broadcast-message-info';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Observable, Subject, map, of, takeWhile, tap, timer } from 'rxjs';
import { ButtonComponent } from '../button/button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'app-broadcast-message-info-dialog',
  standalone: true,
  imports: [CommonModule, ButtonComponent, AngularSvgIconModule],
  templateUrl: './broadcast-message-info-dialog.component.html',
  styleUrls: ['./broadcast-message-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'dialog app-broadcast-message-info-dialog' }
})
export class BroadcastMessageInfoDialogComponent extends DialogBase {

  countdownTime$: Observable<number>

  constructor(@Inject(DIALOG_DATA) public data: BroadCastMessageInfo) {
    super()
    if (data.value! > 0) {
      this.countdownTime$ = timer(0, 1000)
        .pipe(
          map((timer) => data.value! - (timer + 1)), // 0, 1, 2, 3, 4, 5, 6, 7, 8, 9
          tap((timer) => {
            if (timer <= 0) {
              this.close()
            }
          }),
          takeWhile((timer) => timer > 0)
        )

    }
  }

  onRegister() {
    this.dialogRef.close(this.data.value2)
  }
}
