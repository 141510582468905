import { Assets, Graphics, Text, Application, Container, Texture, Sprite, Loader, TextStyle, ITextStyle, ColorMatrixFilter, Point, FederatedPointerEvent } from 'pixi.js';

export class DragAndDrop {
    private isDragging = false;
    private dragOffset = new Point(0, 0);
    private container: Container;


    lastClickTime = 0; // To store the time of the last click
    doubleClickThreshold = 300; // Time in milliseconds to consider as a double-click


    constructor(container: Container) {
        this.container = container;

        // Enable interactive mode for dragging
        this.container.eventMode = 'static';


        // Add drag events
        this.container.on('pointerdown', this.onDragStart.bind(this));
        this.container.on('pointermove', this.onDragMove.bind(this));
        this.container.on('pointerup', this.onDragEnd.bind(this));
        this.container.on('pointerupoutside', this.onDragEnd.bind(this));

        this.container.on('click', (event) => {
            const currentTime = Date.now();

            // Check if the time between clicks is less than the threshold (i.e., it's a double-click)
            if (currentTime - this.lastClickTime < this.doubleClickThreshold) {
                // Double-click detected
                console.log('🎲 Element position', { x: Math.round(this.container.position.x), y: Math.round(this.container.position.y) });
            } else {
                //Single click detected
            }

            // Update last click time
            this.lastClickTime = currentTime;

        })
    }

    // Handle drag start
    onDragStart(event: FederatedPointerEvent) {
        this.isDragging = true;
        const mousePosition = event.data.global;
        this.dragOffset.x = mousePosition.x - this.container.x;
        this.dragOffset.y = mousePosition.y - this.container.y;
    }

    // Handle drag move
    onDragMove(event: FederatedPointerEvent) {
        if (this.isDragging) {
            const mousePosition = event.data.global;
            this.container.position.set(
                mousePosition.x - this.dragOffset.x,
                mousePosition.y - this.dragOffset.y
            );
        }
    }

    // Handle drag end
    onDragEnd() {
        this.isDragging = false;
    }
}