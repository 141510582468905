<div class="dialog-header">
    <div class="dialog-header-title">
        Admin Message
    </div>
    <div class="dialog-header-close-button">
        <button (click)="close()">
            <svg-icon src="assets/img/icons/dialog-close.svg"
                [stretch]="true"
                [applyClass]="true"
                class="dialog-header-close-button-icon" />
        </button>
    </div>
</div>
<div class="dialog-content app-broadcast-message-info-dialog-content">
    <div class="app-broadcast-message-info-dialog-content-text"
        [innerHTML]="data.text"></div>
</div>

<div class="dialog-footer app-broadcast-message-info-dialog-footer">
    <div
        class="dialog-footer-buttons app-broadcast-message-info-dialog-footer-buttons"
        [ngStyle]="{'justify-content': !!data.value2 ? 'space-between' : 'center'  }">
        <button appButton (click)="close()">Close <span
                class="app-broadcast-message-info-dialog-footer-buttons-countdown"
                *ngIf="countdownTime$ | async as countdownTime">{{countdownTime}}s</span></button>
        <button appButton *ngIf="data.value2" class="success"
            (click)="onRegister()">Register</button>
    </div>
</div>