<div class="dialog-header">
    <div class="dialog-header-title">
        Legend Symbol
    </div>
    <div class="dialog-header-close-button">
        <button (click)="close()">
            <svg-icon src="assets/img/icons/dialog-close.svg"
                [stretch]="true"
                [applyClass]="true"
                class="dialog-header-close-button-icon" />
        </button>
    </div>
</div>
<div class="dialog-content">
    <div class="app-lobby-dialog-legends-item" *ngFor="let legendItem of legend">
        <span class="app-lobby-dialog-legends-item-icon">
            <img src="assets/img/icons/legend/{{legendItem.image}}.svg">
        </span>
        <span class="app-lobby-dialog-legends-item-label">
            {{legendItem.label |  translate}}
        </span>
    </div>
</div>
<div class="dialog-footer">
    <button appButton (click)="close()">Close</button>
</div>