import { Point } from "../models/point";

export const type = 'horizontal';

export const tableOffsetTop = 160;
export const tableShadowOffsetTop = 280;
export const tableLogoOffsetTop = 300;
export const tableTextOffsetTop = 10; // offset from Logo

export const gameWidth = 1920;
export const gameHeight = 930;


export const tablePotTotal = { x: 960, y: 295 }; // offset from Logo
export const tableCommunityCardScale = 1.35;
// ==============================================
// =============== SEATS POSITIONS =============== 
// ==============================================

export const SeatPositions: Point[] = [
    { x: 556, y: 67 },
    { x: 896, y: 67 },
    { x: 1255, y: 67 },


    { x: 1567, y: 237 },
    { x: 1567, y: 567 },

    { x: 1255, y: 738 },
    { x: 896, y: 738 },
    { x: 556, y: 738 },

    { x: 223, y: 567 },
    { x: 223, y: 237 },
];


export const SeatPositionsFor6Players: Point[] = [
    { x: 356, y: 118 },
    { x: 895, y: 70 },
    { x: 1434, y: 118 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1434, y: 693 },
    { x: 895, y: 736 },
    { x: 356, y: 693 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];


export const SeatsVisibility = { // <======================================== 📖 its not used
    0: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 0 players - NOT POSSIBLE
    1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 1 player - NOT POSSIBLE
    2: [0, 1, 0, 0, 0, 0, 1, 0, 0, 0], // 2 players
    3: [0, 0, 0, 1, 0, 0, 1, 0, 0, 1], // 3 players
    4: [0, 1, 0, 1, 0, 0, 1, 0, 0, 1], // 4 players
    5: [1, 0, 1, 0, 1, 0, 1, 0, 1, 0], // 5 players
    6: [1, 1, 1, 0, 0, 1, 1, 1, 0, 0], // 6 players
    7: [1, 0, 1, 1, 0, 1, 1, 1, 0, 1], // 7 players
    8: [1, 1, 1, 1, 0, 1, 1, 1, 1, 0], // 8 players
    9: [1, 0, 1, 1, 1, 1, 1, 1, 1, 1], // 9 players
    10: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]  // 10 players
};

export const SeatsVisibilityWithDealer = {
    0: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 0 players - NOT POSSIBLE
    1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 1 player - NOT POSSIBLE
    2: [0, 0, 0, 0, 1, 0, 0, 0, 1, 0], // 2 players
    3: [1, 0, 1, 0, 0, 0, 1, 0, 0, 0], // 3 players
    4: [0, 1, 0, 1, 0, 0, 1, 0, 0, 1], // 4 players
    5: [1, 0, 1, 0, 1, 0, 1, 0, 1, 0], // 5 players
    6: [1, 1, 1, 0, 0, 1, 1, 1, 0, 0], // 6 players
    7: [1, 0, 1, 1, 0, 1, 1, 1, 0, 1], // 7 players
    8: [1, 1, 1, 1, 0, 1, 1, 1, 1, 0], // 8 players
    9: [1, 0, 1, 1, 1, 1, 1, 1, 1, 1], // 9 players
    10: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]  // 10 players
};



// =================================================
// =============== PLAYERS POSITIONS =============== 
// =================================================


export const PlayerPositions: Point[] = [
    { x: 419, y: 34 },
    { x: 784, y: 34 },
    { x: 1149, y: 34 },

    { x: 1514, y: 160 },
    { x: 1514, y: 465 },

    { x: 1149, y: 631 },
    { x: 784, y: 631 },
    { x: 419, y: 631 },

    { x: 49, y: 465 },
    { x: 49, y: 160 },
];

export const PlayerPositionsFor6Players: Point[] = [
    { x: 243, y: 55 },
    { x: 783, y: 0 },
    { x: 1322, y: 55 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1322, y: 631 },
    { x: 783, y: 636 },
    { x: 243, y: 631 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];


// =============================================================
// =============== PLAYERS FOLDED CARDS POSITIONS =============== 
// ==============================================================

// 📚 Foled cards positions go slightly up than setted Y position

export const FoldedCardsPositions: Point[] = [
    { x: 671, y: 310 },
    { x: 957, y: 310 },
    { x: 1317, y: 310 },

    { x: 1505, y: 375 },
    { x: 1505, y: 507 }, // center

    { x: 1317, y: 590 },
    { x: 957, y: 590 },
    { x: 671, y: 590 },

    { x: 406, y: 507 },
    { x: 406, y: 375 },
];
export const FoldedCardsPositionsFor6Players: Point[] = [
    { x: 418, y: 365 },
    { x: 957, y: 310 },
    { x: 1495, y: 365 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1495, y: 575 },
    { x: 957, y: 580 },
    { x: 418, y: 575 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];


// ==============================================================
// =============== TABLE CARDS  =================================
// ==============================================================

export const CommunityCardPositions: Point[] = [
    { x: 660, y: 355 },
    { x: 780, y: 355 },
    { x: 900, y: 355 }, // top-left
    { x: 1020, y: 355 },
    { x: 1140, y: 355 }
];
export const R2tT1Positions: Point[] = [
    { x: 660, y: 260 },
    { x: 780, y: 260 },
    { x: 900, y: 260 }, // top-left
    { x: 1020, y: 260 },
    { x: 1140, y: 260 }
];
export const R2tT2Positions: Point[] = [
    { x: 660, y: 440 },
    { x: 780, y: 440 },
    { x: 900, y: 440 }, // top-left
    { x: 1020, y: 440 },
    { x: 1140, y: 440 }
];
// export const R2tCardScale: Point = { x: 1.1, y: 1.1 };
// export const CommunityCardScale: Point = { x: 1.35, y: 1.35 };


// ==============================================================
// =============== POT POSITIONS  =================================
// ==============================================================


export const PotPositions: Point[] = [
    { x: 594, y: 301 },
    { x: 959, y: 301 },
    { x: 1329, y: 301 },

    { x: 1459, y: 366 },
    { x: 1459, y: 581 }, // center

    { x: 1329, y: 651 },
    { x: 959, y: 651 },
    { x: 594, y: 651 },

    { x: 450, y: 581 },
    { x: 450, y: 366 },
];


export const PotPositionsFor6Players: Point[] = [
    { x: 519, y: 327 },
    { x: 990, y: 285 },
    { x: 1399, y: 327 },
    { x: 0, y: 0 },
    { x: 0, y: 0 }, // center
    { x: 1399, y: 622 },
    { x: 960, y: 642 },
    { x: 519, y: 622 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];


export const MainPotPositions: Point[] = [
    { x: 490, y: 360 }, //@MainPot 1 center
    { x: 490, y: 410 } //@MainPot 2 
];



export const flyingTableCard = { x: 960, y: 295 };
