
import { Injectable } from '@angular/core';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ServerResponse } from '@app/enums/server-response';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LobbyService } from '../../../features/lobby/services/lobby.service';

import * as LobbyActions from '../lobby/lobby.actions';
import * as TournamentSummariesActions from '../tournament-summaries/tournament-summaries.actions';
import { ServerMessageType } from '@app/enums/server-message-type';

import { Store, select } from '@ngrx/store';

import * as TablesActions from './tables.actions';
import * as TablesSelectors from './tables.selector';
import * as TableSummariesSelectors from '@app/store/features/table-summaries/table-summaries.selectors';

import { VariantType2 } from '@app/enums/variant-type-2';
import { WsService } from '@app/services/ws.service';
import { ServerMsgInfo } from '@app/models/server-msg';
import { LiveLobbyRing } from '@app/models/live-lobby-ring';
import { TableSummary } from '@app/models/table-summary';
import { LiveLobbyRingSelected } from '@app/models/live-lobby-ring-selected';
import { TableInfo } from '@app/models/table-info';
import { MixTableDetails, MixTableDetailsDTO } from '@app/models/mix-table-details';

@Injectable()
export class TablesEffects {
    constructor(
        private _ws: WsService,
        private readonly store: Store,
        private readonly lobbyService: LobbyService,
        private actions$: Actions,

    ) { }


    


    onSelectTable$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LobbyActions.onSelectTable),
                tap(({ tableSummary }) => {
                    this.lobbyService.getTableDetails(tableSummary.id);
                    this.lobbyService.subscribeLiveLobbySelected(tableSummary.id);
                    if (tableSummary.variant2 === VariantType2.MixTable) {
                        this.lobbyService.getMixTablesDetails(tableSummary.id);
                    }
                }),
                map(({ tableSummary }) => TablesActions.upsertOne({ table: { id: tableSummary.id, tableSummary: tableSummary } }))
            ));


    updateTableInfo$ = createEffect(() =>
        this._ws.getServerMsg<ServerMsgInfo>(ServerMessageType.Info)
            .pipe(
                map(serverMsgInfo => {
                    return TablesActions.upsertOne({ table: { id: serverMsgInfo.idTable, tableInfo: serverMsgInfo.info } })
                })
            )
    );

    updateLiveLobbyRing$ = createEffect(() =>
        this._ws.getDataResponse<LiveLobbyRing>(ServerResponse.LiveLobbyRing)
            .pipe(
                switchMap(liveLobbyRing => {
                    return this.store.pipe(
                        select(TableSummariesSelectors.selectEntityById(liveLobbyRing.idTable)),
                        take(1),
                        map(tableSummary => {
                            if (liveLobbyRing.removed) {
                                return TablesActions.removeOne({ tableId: liveLobbyRing.idTable })
                            }


                            const updateTableSummary: TableSummary = {
                                ...tableSummary as TableSummary,
                                playerCount: liveLobbyRing.nbPlayers,
                                averagePot: liveLobbyRing.averagePot,
                                playerFlop: liveLobbyRing.playerFlop,
                                handPerHour: liveLobbyRing.handPerHour,
                                waitingList: liveLobbyRing.waitingList,
                            }
                            return TablesActions.upsertOne({ table: { id: liveLobbyRing.idTable, tableSummary: updateTableSummary } })
                        })
                    )
                })
            )
    );

    updateLiveLobbyRingSelected$ = createEffect(() =>
        this._ws.getDataResponse<LiveLobbyRingSelected>(ServerResponse.LiveLobbyRingSelected)
            .pipe(
                switchMap(liveLobbyRingSelected => {
                    return this.store.pipe(
                        select(TablesSelectors.selectEntityById(liveLobbyRingSelected.idTable)),
                        take(1),
                        map(table => {

                            const tableSummary: TableSummary = {
                                ...table!.tableSummary as TableSummary,
                                waitingListName: liveLobbyRingSelected.waitingListName,
                            }

                            const tableInfo: TableInfo = {
                                ...table!.tableInfo as TableInfo,
                                players: liveLobbyRingSelected.players.map(player => {
                                    return {
                                        id: player.playerId,
                                        name: player.name,
                                        money: player.money,
                                        rank: player.rank
                                    }
                                })
                            }
                            return TablesActions.upsertOne({ table: { id: liveLobbyRingSelected.idTable, tableSummary, tableInfo } })
                        })
                    )
                })
            )
    );

    updateMixTablesDetails$ = createEffect(() =>
        this._ws.getDataResponse<MixTableDetailsDTO>(ServerResponse.MixTablesDetails)
            .pipe(
                map(mixTablesDetailsDTO => {

                    const mixTableDetails: MixTableDetails = {
                        ...mixTablesDetailsDTO,
                        tables: mixTablesDetailsDTO.tables.map(table => this.lobbyService.updateMixTableDetails(table))
                    }

                    return TablesActions.updateOne({ tableId: mixTableDetails.idTable, table: { mixTableDetails } })
                })
            )
    );
}