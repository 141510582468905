export enum ServerResponse {
    MemberProfile = 'MemberProfile',
    MemberPreference = 'MemberPreference',
    CurrenciesInfo = 'CurrenciesInfo',
    TableSummaries = 'TableSummaries',
    TournamentsSummaries = 'TournamentsSummaries',
    SitNGoSummaries = 'SitNGoSummaries',
    SpinNGoSummaries = 'SpinNGoSummaries',

    LiveLobbyRing = 'LiveLobbyRing',
    LiveLobbyRingSelected = 'LiveLobbyRingSelected',

    TournamentSummary = 'TournamentSummary',
    TournamentInformation = 'TournamentInformation',
    BlindInformation = 'BlindInformation',

    MixTablesDetails = 'MixTablesDetails',

    Settings = 'Settings',

    JackPotsInfo = 'JackPotsInfo',
    CasinosInfo = 'CasinosInfo',

    SkinsInfo = 'SkinsInfo',

    Tickets = 'Tickets',

    PlayerLevelStatus = 'PlayerLevelStatus',

    Gifts = 'Gifts',
    GiftUnlocked = 'GiftUnlocked',

    RAFBonuses = 'RAFBonuses',

    GatewaysInfo = 'GatewaysInfo',
    TransactionsHistory = 'TransactionsHistory',
    HandReplayData = 'HandReplayData',
    HandHistory = 'HandHistory',
    CallTimeStatus = 'CallTimeStatus',


    MixTableVariantUpdate = 'MixTableVariantUpdate',
}