
import { Injectable, inject } from '@angular/core';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ServerResponse } from '@app/enums/server-response';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LobbyService } from '../../../features/lobby/services/lobby.service';


import * as ConfigSelectors from '@app/store/features/config/config.selectors';

import { WsService } from '@app/services/ws.service';
import { TableSummary, TableSummaryData } from '@app/models/table-summary';
import { LiveLobbyRing } from '@app/models/live-lobby-ring';
import * as PlayerBalanceSelectors from '@app/store/features/player-balance/player-balance.selectors';
import { ServerMsgPlayerBalance } from '@app/models/server-msg';
import { ServerMessageType } from '@app/enums/server-message-type';
import { PlayerBalance } from '@app/models/player-balance';
import { AccountType } from '@app/models/account-type';
import { UserService } from '@app/features/user/services/user.service';
import { PlayerLevelStatusDTO } from '@app/models/player-level-status';
import * as CurrenciesSelectors from '@app/store/features/currencies/currencies.selectors';
import * as PlayerLevelStatusActions from '@app/store/features/player-level-status/player-level-status.actions';
import { combineLatest } from 'rxjs';
import { GiftDTO } from '@app/models/gift';
import * as RAFBonusesActions from '@app/store/features/raf-bonuses/raf-bonuses.actions';
import { RAFBonuseDTO } from '@app/models/raf-bonuses';

@Injectable()
export class RAFBonusesEffects {
    private readonly _ws = inject(WsService);
    private readonly _actions$ = inject(Actions);
    private readonly _store = inject(Store);


    gifts$ = createEffect(() => this._ws.getDataResponse<RAFBonuseDTO[]>(ServerResponse.RAFBonuses)
        .pipe(
            map(rafBonuses => {
                return RAFBonusesActions.setAll({ rafBonuses })
            })
        ));
} 