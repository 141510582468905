import { ConnectionStatus } from '@app/enums/connection-status';
import { createAction, props } from '@ngrx/store';

export const updateConnectionStatus = createAction('[WS] updateConnectionStatus', props<{ status: ConnectionStatus }>());
export const updatePing = createAction('[WS] updatePing')
export const updatePong = createAction('[WS] updatePong')
export const resetPingPong = createAction('[WS] resetPingPong')

export const connectWebSocket = createAction('[WS] connectWebSocket', props<{ wsUrl: string, token: string, skin: string }>())

