import { createAction, props } from '@ngrx/store';
import { ChargedRabbitHunting, Game, MemberPreferencesRunItTwice, PreBetType, RabbitHuntingPrice, ReplayAction } from './games.reducer';
import { Container } from 'pixi.js';
import { NoteColors } from '@app/features/game/enums/note-colors';
import { HandHistory } from '@app/models/hand-history';
import { PlayerCallTime } from '@app/models/player-call-time';
import { TableSession, TableSessionType } from '@app/features/game/models/table-session';
import { HandInfo } from '@app/models/hand-info';

export const upsertOne = createAction('[Games] Upsert Table', props<{ game: Game }>());
export const removeOne = createAction('[Games] Remove Table', props<{ idTable: number }>());
export const updateOne = createAction('[Games] Update Table', props<{ idTable: number; game: Partial<Game> }>());

export const onOpenGame = createAction('[Games] Open Game', props<{ idTable: number }>());

export const initGame = createAction('[Games] Init Game', props<{ idTable: number }>());

// Hand Replay
export const onOpenHandReplay = createAction('[Games] Open Hand Replay', props<{ handId: number }>());
export const setActiveHandReplay = createAction('[Games] setActiveHandReplay', props<{ handId: number }>());
export const onHandReplayActionPlay = createAction('[Games] onHandReplayActionPlay', props<{ idTable: number }>());
export const onHandReplayActionNext = createAction('[Games] onHandReplayActionNext', props<{ idTable: number }>());
export const onHandReplayActionPause = createAction('[Games] onHandReplayActionPause', props<{ idTable: number }>());
export const onHandReplayActionRestart = createAction('[Games] onHandReplayActionRestart', props<{ idTable: number }>());


export const getHandHistory = createAction('[Games] getHandHistory', props<{ handId?: number }>());
export const updateHandHistory = createAction('[Games] updateHandHistory', props<HandHistory>());

//+ Table Sessions
export const goToTableSessions = createAction('[Games] goToTableSessions', props<{
    search: {
        from?: string;
        to?: string;
        offset?: number;
        limit?: number;
        sessionType?: TableSessionType;
    }
}>());

export const searchTableSessions = createAction('[Games] searchTableSessions', props<{
    search: {
        from?: string;
        to?: string;
        offset?: number;
        limit?: number;
        sessionType?: TableSessionType;
    }
}>());
export const updateTableSessions = createAction('[Games] updateTableSessions', props<{
    tableSessions: {
        totalRows: number,
        sessions: TableSession[],
        search: {
            from?: string;
            to?: string;
            offset?: number;
            limit?: number;
            sessionType?: TableSessionType;
        },
        loader: boolean
    }
}>());



export const getHandSessions = createAction('[Games] getHandSessions', props<{
    search: {
        handsSessionId: number,
        offset?: number;
        limit?: number;
    }
}>());
export const updateHandSessions = createAction('[Games] updateHandSessions', props<{
    handSessions: {
        totalRows: number,
        sessions: HandInfo[],
        search: {
            handsSessionId: number,
            offset?: number;
            limit?: number;
        },
        loader: boolean
    }
}>());

export const takeSeat = createAction('[Games] Take Seat', props<{ idTable: number, seatPosition: number }>());


export const foldConfirmed = createAction('[Games] Action Fold Confirmed', props<{ idTable: number }>());
export const foldCanceled = createAction('[Games] Action Fold Canceled', props<{ idTable: number }>());

export const unsetActiveTable = createAction('[Games] unsetActiveTable');
export const setActiveTable = createAction('[Games] setActiveTable', props<{ idTable: number }>());
export const setPreBetControl = createAction('[Games] setPreBetControl', props<{ idTable: number, preBetType: PreBetType, checked: boolean }>());

// Action Controls
export const actionControlIAmBack = createAction('[Games] Action Control I am back', props<{ idTable: number }>());
export const actionControlBuyRebuyChips = createAction('[Games] Action Buy Rebuy Chips', props<{ idTable: number }>());
export const actionControlLeaveTable = createAction('[Games] Action Leave Table', props<{ idTable: number }>());
export const actionControlLeaveSeat = createAction('[Games] Action Leave Seat', props<{ idTable: number }>());

// Bet Actions 
export const onBetActionPostBigBlind = createAction('[Games] On Bet Action Post BigB lind', props<{ idTable: number }>());
export const onBetActionFold = createAction('[Games] On Bet Action Fold', props<{ idTable: number }>());
export const onBetActionCall = createAction('[Games] On Bet Action Call', props<{ idTable: number }>());
export const onBetActionCheck = createAction('[Games] On Bet Action Check', props<{ idTable: number }>());
export const onBetActionRaise = createAction('[Games] On Bet Action Raise', props<{ idTable: number }>());
export const onBetActionBB2 = createAction('[Games] On Bet Action BB2', props<{ idTable: number }>());
export const onBetActionBB3 = createAction('[Games] On Bet Action BB3', props<{ idTable: number }>());
export const onBetActionBB4 = createAction('[Games] On Bet Action BB4', props<{ idTable: number }>());
export const onBetActionQuarterPot = createAction('[Games] On Bet Action Quarter Pot', props<{ idTable: number }>());
export const onBetActionThreeQuartersPot = createAction('[Games] On Bet Action Three Quarters Pot', props<{ idTable: number }>());
export const onBetActionHalfPot = createAction('[Games] On Bet Action Half Pot', props<{ idTable: number }>());
export const onBetActionPot = createAction('[Games] On Bet Action Pot', props<{ idTable: number }>());

// Bet Actions Slider
export const onBetActionSliderMin = createAction('[Games] On Bet Action Slider Min', props<{ idTable: number }>());
export const onBetActionSliderMax = createAction('[Games] On Bet Action Slider Max', props<{ idTable: number }>());
export const onBetActionSliderDecrease = createAction('[Games] On Bet Action Slider Decrease', props<{ idTable: number }>());
export const onBetActionSliderIncrease = createAction('[Games] On Bet Action Slider Increase', props<{ idTable: number }>());
export const onBetActionSliderChange = createAction('[Games] On Bet Action Slider Change', props<{ idTable: number, value: number }>());
export const onBetActionSliderInputChange = createAction('[Games] On Bet Action Slider Input Change', props<{ idTable: number, value: number }>());

// Chat
export const onChatSendMessage = createAction('[Games] On Chat Send Message', props<{ idTable: number, value: string }>());

// R2T
export const onRunItTwiceTable = createAction('[Games] On Run It Twice Table', props<{ idTable: number, value: MemberPreferencesRunItTwice }>());
export const onRunItTwiceGlobal = createAction('[Games] On Run It Twice Global', props<{ value: MemberPreferencesRunItTwice }>());


// User
export const onOpenUserInfoDialog = createAction('[Games] On Open User Info Dialog', props<{ tableId: number, playerId: number, playerName: string, noteColor: keyof typeof NoteColors, noteText: string }>());

// Waiting List
export const onJoinWaitingList = createAction('[Games] On Join Waiting List', props<{ idTable: number }>());
export const onLeaveWaitingList = createAction('[Games] On Leave Waiting List', props<{ idTable: number }>());

// Rabbit Hunting
export const freeRabbitHunting = createAction('[Games] freeRabbitHunting', props<{ idTable?: number, value: number }>());
export const chargedRabbitHunting = createAction('[Games] ChargedRabbitHunting', props<{ idTable: number, chargedRabbitHunting: ChargedRabbitHunting }>());
export const updateRabbitHuntingPrice = createAction('[Games] updateRabbitHuntingPrice', props<{ idTable: number, rabbitHuntingPrice: RabbitHuntingPrice }>());
export const onOfferRabbitHunting = createAction('[Games] onOfferRabbitHunting', props<{ idTable: number }>());

// Call Time 
export const openCallTimeDialog = createAction('[Games] openCallTimeDialog', props<{ idTable: number, playerCallTime?: PlayerCallTime }>());