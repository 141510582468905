
import { Injectable, inject } from '@angular/core';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ServerResponse } from '@app/enums/server-response';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LobbyService } from '../../../features/lobby/services/lobby.service';


import * as ConfigSelectors from '@app/store/features/config/config.selectors';

import { WsService } from '@app/services/ws.service';
import { TableSummary, TableSummaryData } from '@app/models/table-summary';
import { LiveLobbyRing } from '@app/models/live-lobby-ring';
import * as PlayerBalanceSelectors from '@app/store/features/player-balance/player-balance.selectors';
import { ServerMsgPlayerBalance } from '@app/models/server-msg';
import { ServerMessageType } from '@app/enums/server-message-type';
import { PlayerBalance } from '@app/models/player-balance';
import { AccountType } from '@app/models/account-type';
import { UserService } from '@app/features/user/services/user.service';
import { PlayerLevelStatusDTO } from '@app/models/player-level-status';
import * as CurrenciesSelectors from '@app/store/features/currencies/currencies.selectors';
import * as PlayerLevelStatusActions from '@app/store/features/player-level-status/player-level-status.actions';
import { combineLatest } from 'rxjs';

@Injectable()
export class PlayerLevelStatusEffects {
    private readonly _ws = inject(WsService);
    private readonly _actions$ = inject(Actions);
    private readonly _store = inject(Store);
    private readonly _userService = inject(UserService);


    playerLevelStatus$ = createEffect(() => this._ws.getDataResponse<PlayerLevelStatusDTO>(ServerResponse.PlayerLevelStatus)
        .pipe(
            switchMap((playerLevelStatus) => {

                return combineLatest([
                    this._store.pipe(select(CurrenciesSelectors.selectEntityById(playerLevelStatus.currency))),
                    this._store.pipe(select(ConfigSelectors.getGlobalSettings))
                ])
                    .pipe(
                        // filter([currency, globalSettings] => currency && globalSetting)),
                        filter(([currencyInfo, globalSettings]) => !!(currencyInfo && globalSettings)),
                        map(([currencyInfo, globalSettings]) => this._userService.toPlayerLevelStatus(playerLevelStatus, currencyInfo!, globalSettings!)),
                        take(1))
            }),
            map(playerLevelStatus => {
                return PlayerLevelStatusActions.upsertOne({ playerLevelStatus })
            }),

        ));
} 