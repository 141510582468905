
import { Injectable } from '@angular/core';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ServerResponse } from '@app/enums/server-response';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LobbyService } from '../../../features/lobby/services/lobby.service';

import * as LobbyActions from '../lobby/lobby.actions';
import * as TableSummariesActions from './table-summaries.actions';
import * as TableSummariesSelectors from './table-summaries.selectors';

import * as ConfigSelectors from '@app/store/features/config/config.selectors';

import { WsService } from '@app/services/ws.service';
import { TableSummary, TableSummaryData } from '@app/models/table-summary';
import { LiveLobbyRing } from '@app/models/live-lobby-ring';

@Injectable()
export class TableSummariesEffects {
    constructor(
        private _ws: WsService,
        private readonly store: Store,
        private readonly lobbyService: LobbyService,
        private actions$: Actions,

    ) { }

    // updateActiveNav$ = createEffect(
    //     () =>
    //         this.actions$.pipe(
    //             ofType(LobbyActions.updateActiveNav),
    //             filter(({ activeNav }) => activeNav === LobbyNav.RingGames),
    //             tap(() => this.lobbyService.getRingTables()),
    //             map(() => TableSummariesActions.updateLoader({ loader: true }))
    //         ));


    getTableSummary$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TableSummariesActions.getTableSummary),
                tap(({ tableId }) => {
                    this.lobbyService.getTableSummary(tableId);
                }),
            ), { dispatch: false }); // Return Table Summaries Array

    updateTableSummaries$ = createEffect(() =>
        this._ws.getDataResponse<TableSummaryData[]>(ServerResponse.TableSummaries)
            .pipe(
                switchMap(tableSummariesResponse => {
                    console.log("🔥 Table Summaries Response", tableSummariesResponse)
                    return this.store.pipe(
                        select(ConfigSelectors.getJackPotsInfo),
                        take(1),
                        map(jackPotsInfo => {
                            const tableSummaries: TableSummary[] = tableSummariesResponse.map(TableSummaryData => {
                                return this.lobbyService.updateTableSummary(TableSummaryData, jackPotsInfo)
                            })

                            return TableSummariesActions.setAll({ tableSummaries })
                        })
                    )

                })
            ));

    updateLiveLobbyRing$ = createEffect(() =>
        this._ws.getDataResponse<LiveLobbyRing>(ServerResponse.LiveLobbyRing)
            .pipe(
                switchMap(liveLobbyRing => {
                    return this.store.pipe(
                        select(TableSummariesSelectors.selectEntityById(liveLobbyRing.idTable)),
                        take(1),
                        switchMap(tableSummary => {
                            return this.store.pipe(
                                select(ConfigSelectors.getJackPotsInfo),
                                take(1),
                                map(jackPotsInfo => {

                                    if (liveLobbyRing.removed) {
                                        return TableSummariesActions.removeOne({ tableId: liveLobbyRing.idTable })
                                    }

                                    const updateTableSummary: TableSummary = {
                                        ...tableSummary as TableSummary,
                                        playerCount: liveLobbyRing.nbPlayers,
                                        averagePot: liveLobbyRing.averagePot,
                                        playerFlop: liveLobbyRing.playerFlop,
                                        handPerHour: liveLobbyRing.handPerHour,
                                        waitingList: liveLobbyRing.waitingList,
                                    }

                                    return TableSummariesActions.updateOne({ tableId: liveLobbyRing.idTable, tableSummary: this.lobbyService.updateTableSummary(updateTableSummary, jackPotsInfo) })
                                })
                            )


                        })
                    )
                })
            )
    );
} 