import { State, createFeatureSelector, createSelector } from "@ngrx/store";
import * as mainReducers from '@app/store/features/main/main.reducers';
import * as TablesReducer from './games.reducer';

export const selectMainState = createFeatureSelector<mainReducers.State>('main');
export const selectGamesState = createSelector(selectMainState, (state) => state.games);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = TablesReducer.adapter.getSelectors(selectGamesState);

export const selectEntityById = (id: number) => createSelector(
    selectEntities,
    (entities) => entities[id]
);


export const selectGameActionByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.action
);

export const selectGameHistoryEventsByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.gameHistoryEvents
);

export const selectGameBetControlsByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.gameBetControls
);

export const selectGameHandNumberByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.handNumber
);

export const selectGameCurrentHandNumberByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.currentHandNumber
);

export const selectGameReplayActionByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.gameReplayAction
);

export const selectGamePreBetControlssByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.gamePreBetControls
);

export const selectGameActionControlssByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.gameActionControls
);

export const selectGameChatByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.chat
);


export const selectGameCurrencyByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.currency
);

export const selectGamePlayersStatisticsByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.playersStatistics ?? {}
);

export const selectGameActive = () => createSelector(
    selectGamesState,
    (state) => state?.activeTableId
);

export const selectGameOpened = () => createSelector(
    selectGamesState,
    (state) => state?.openedTables
);


// 🐇 Rabbit Hunting

export const selectFreeRabbitHunting = () => createSelector(
    selectGamesState,
    (state) => state?.freeRabbitHunting
);


export const selectGameFreeRabbitHuntingByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.freeRabbitHunting
);

export const selectGameChargedRabbitHuntingByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.chargedRabbitHunting
);

export const selectGameRabbitHuntingPriceByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.rabbitHuntingPrice
);

// Hand Type

export const selectGameHandTypeByTableId = (idTable: number) => createSelector(
    selectEntities,
    (entities) => entities[idTable]?.handType
);

export const selectHandHistory = () => createSelector(
    selectGamesState,
    (state) => state?.handHistory
);

export const selectTableSessions = () => createSelector(
    selectGamesState,
    (state) => state?.tableSessions
);

export const selectHandSessions = () => createSelector(
    selectGamesState,
    (state) => state?.handSessions
);
