import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  private readonly _router = inject(Router);


  page$ = this._router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd), // Type narrowing
    map((event: NavigationEnd) => event.urlAfterRedirects),
    map((url: string) => url.startsWith('/') ? url.slice(1) : url),
    shareReplay({ bufferSize: 1, refCount: true })
  )

}
